<template>
  <v-container fluid>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-2">
        Selecione o mês que será restaurado
      </div>
      <first-tab-content
        :itemsTable="itemsTableFirstTab"
        :selectedItem.sync="selectedItemFirstTab"
        :loading.sync="loadingFirstTab"
        @stepReleased="$emit('step-observer', { step: 0, complete: $event })"
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <div class="text-center display-1 font-weight-normal mb-6">
        Selecione as entidades que serão restauradas
      </div>
      <second-tab-content
        :itemsTable="itemsTableSecondTab"
        :selectedItem.sync="selectedItemSecondTab"
        :loading.sync="loadingSecondTab"
        @stepReleased="
          $emit('step-observer', { step: 1, complete: $event }),
            $emit('step-observer', { step: 2, complete: $event })
        "
      />
    </v-tab-item>
    <v-tab-item
      class="pb-0"
      eager
    >
      <third-tab-content
        :records="totalRecordsThirdTab"
        @excluirBdgd="(e) => atualizaFlagExcluirBdgd(e)"
      />
    </v-tab-item>
  </v-container>
</template>

<script>
import RestauracoesBdgdsService from '@/services/RestauracoesBdgdsService';

export default {
  props: {
    items: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    FirstTabContent: () =>
      import('@/components/dados/restaurar-bdgd/tabs/FirstTabContent.vue'),
    SecondTabContent: () =>
      import('@/components/dados/restaurar-bdgd/tabs/SecondTabContent.vue'),
    ThirdTabContent: () =>
      import('@/components/dados/restaurar-bdgd/tabs/ThirdTabContent.vue')
  },
  created() {
    this.fillFirstTab();
  },
  data() {
    return {
      loadingFirstTab: false,
      loadingSecondTab: false,
      itemsTableFirstTab: [],
      itemsTableSecondTab: [],
      excluirBdgd: false,
      selectedItemFirstTab: [],
      selectedItemSecondTab: [],
      totalRecordsThirdTab: 0
    };
  },
  computed: {
    userSelectedCompanyBdgdVersion() {
      return this.$store.getters.getUserSelectedCompanyBdgdVersion;
    }
  },
  methods: {
    fillFirstTab() {
      this.loadingFirstTab = true;
      RestauracoesBdgdsService.getDatesAndRegistersImportedEntities()
        .then((response) => {
          this.itemsTableFirstTab = response.data;
          const selectedBdgdVersion = this.itemsTableFirstTab.filter(
            (item) =>
              item.bdgd_versao_id === this.userSelectedCompanyBdgdVersion.id
          );
          this.selectedItemFirstTab = selectedBdgdVersion;
        })
        .finally(() => (this.loadingFirstTab = false));
    },
    fillSecondTab(selectedDate, companyId, bdgdVersionId) {
      this.loadingSecondTab = true;
      RestauracoesBdgdsService.getImportedEntitiesAndRegistersByDate(
        selectedDate,
        companyId,
        bdgdVersionId
      )
        .then((response) => (this.itemsTableSecondTab = response.data))
        .catch(() =>
          this.$emit('stepReleased', { numberStep: 1, available: false })
        )
        .finally(() => (this.loadingSecondTab = false));
    },
    exportData() {
      let arrTabelas = [];
      this.selectedItemSecondTab.forEach((item) => {
        arrTabelas.push(item.entidade);
      });
      return {
        tabelas: JSON.stringify(arrTabelas),
        data_registro: this.selectedItemFirstTab[0].data_registro,
        company_id: this.selectedItemFirstTab[0].empresa_id,
        bdgd_versao_id: this.selectedItemFirstTab[0].bdgd_versao_id,
        numero_linhas: this.totalRecordsThirdTab,
        excluir_bdgd: this.excluirBdgd,
        usuario_id: this.$store.getters.getUserId,
        status: 'AGUARDANDO'
      };
    },
    atualizaFlagExcluirBdgd(bool) {
      this.excluirBdgd = bool;
    }
  },
  watch: {
    selectedItemFirstTab(arrNewVal) {
      let selectedDate = arrNewVal.length
        ? arrNewVal[0].data_registro
        : undefined;
      let companyId = arrNewVal.length ? arrNewVal[0].empresa_id : undefined;
      let bdgdVersionId = arrNewVal.length
        ? arrNewVal[0].bdgd_versao_id
        : undefined;
      if (selectedDate && companyId && bdgdVersionId) {
        this.fillSecondTab(selectedDate, companyId, bdgdVersionId);
      }
    },
    selectedItemSecondTab(arrNewVal) {
      let totalRecords = 0;
      if (arrNewVal.length) {
        totalRecords = arrNewVal
          .map((item) => item.registros)
          .reduce((prev, next) => prev + next);
      }
      this.totalRecordsThirdTab = totalRecords;
    }
  }
};
</script>
